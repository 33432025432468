import weightConverter from "@/helpers/weightConverter";
import {Units} from "@/types/common";

export class MappedWeight {
  readonly kilos: number;
  readonly grams: number;
  readonly kilograms: number;
  readonly ounces: number;
  readonly pounds: number
  constructor(public readonly weight: number) {
    [this.kilos, this.grams, this.kilograms] = weightConverter.poundsToKgAndGrams(weight);
    [this.pounds, this.ounces] = weightConverter.poundToOunce(weight);
  }

  update(value: string, unit: Units): MappedWeight {
    let newWeight;
    if (Units.KG_DECIMAL === unit) {
      newWeight = weightConverter.kgToPound(+value)
    }
    else if ([Units.KG, Units.GRAMS].includes(unit)) {
      const kgWeight = unit === Units.KG
        ? +value + this.grams / 1000
        : this.kilos + (+value) / 1000
      newWeight = weightConverter.kgToPound(kgWeight)
    }
    else {
      newWeight = unit === Units.POUNDS
        ? weightConverter.ounceToPound(+value, this.ounces)
        : weightConverter.ounceToPound(this.pounds, +value)

    }
    return new MappedWeight(newWeight);
  }
}
