import {Box, Stack, Typography} from '@mui/material';
import Portion from '@/assets/icons/portion.svg';
import {useDietPlans} from '@/app/hooks';
import {DietPlan, SubscriptionProductType} from "@/types/common";


function FullDietPortionWidget({selectedDiet}: {selectedDiet?: DietPlan}) {
  return (
    <Stack spacing={2}>
      <Box>
        <Typography>Adjusted daily calories:</Typography>
        <Typography variant={'subtitle2'}>{selectedDiet?.recommendedCalories} kcals</Typography>
      </Box>
      <Box>
        <Typography>Meals per day:</Typography>
        <Typography variant={'subtitle2'}>{selectedDiet?.feedingsPerDay}</Typography>
      </Box>
      <Box>
        <Typography>Packages per meal:</Typography>
        <Typography
          variant={'subtitle2'}>{selectedDiet?.portionPerMealText}</Typography>
      </Box>
    </Stack>
  )
}
function MixedDietPortionWidget({selectedDiet}: {selectedDiet: DietPlan}) {
  const totalCalories = selectedDiet.recommendedCalories;
  return (
    <Stack spacing={2}>
      <Box>
        <Typography>Adjusted daily calories:</Typography>
        <Typography variant={'subtitle2'}>{totalCalories} kcals</Typography>
      </Box>
      <Box>
        <Typography>Packages per day:</Typography>
        <Typography
          variant={'subtitle2'}>{selectedDiet.halfDailyPortionText}</Typography>
      </Box>
      <Box>
        <Typography>Calories required from other food:</Typography>
        <Typography
          variant={'subtitle2'}>{Math.round(totalCalories/2)}</Typography>
      </Box>
    </Stack>
  )
}

const PortionWidget = () => {
  const { selectedDiet } = useDietPlans();

  if (!selectedDiet) {
    return null;
  }

  const isMixedDiet = selectedDiet.subscriptionSummary?.subscriptionType === SubscriptionProductType.WeightLossMixedPlan
  return isMixedDiet ? <MixedDietPortionWidget selectedDiet={selectedDiet} /> : <FullDietPortionWidget selectedDiet={selectedDiet} />;
};

export default () => ({
  key: 'portioning',
  label: 'Portioning',
  icon: Portion,
  widget: <PortionWidget />,
  isOpen: true,
});
