export function fireGTMEvent(event: string, eventProps?: any) {
  if (!('dataLayer' in window)) {
    // @ts-ignore
    window.dataLayer = [];
  }
  // @ts-ignore
  window.dataLayer.push({
    event,
    eventProps,
  });
}
