export type SimpleMenuItemType = {
  label: string;
  key: string | number;
};

export enum Units {
  'POUNDS' = 'POUNDS',
  'KG' = 'KG',
  'KG_DECIMAL' = 'KG_DECIMAL',
  'GRAMS' = 'GRAMS',
  'OUNCES' = 'OUNCES',
}

export type IsoDateString = string;
export type IsoDateTimeString = string;

export type DietPlan = {
  createdOn: IsoDateTimeString;
  startOn: IsoDateString;
  finishOn: IsoDateString;
  status: 'active';
  initialWeight: number;
  idealWeight: number;
  // initialBCS: number;
  updatedOn: IsoDateTimeString;
  initialPlan: Array<{ week: number; targetWeight: number }>;
  progress: Array<{ date: IsoDateString; bcs: number | null; weight: number }>;
  forecast: Array<{ date: IsoDateString; weight: number }>;
  owner: {
    email: string;
    firstName: string;
    lastName: string;
    shopifyUserId: number;
    phone: null | string;
  };
  patient: {
    id: string;
    dob: IsoDateString;
    dogName: string;
    clinicName: string;
    breed: string;
    activityLevel: 'High' | 'Moderate' | 'Low';
    gender: 'MALE' | 'FEMALE';
    neutered: boolean;
    matchedDogSize: 'Small' | 'Medium' | 'Large';
    subscriptionId: string;
  };
  recommendedCalories: number;
  dailyPortion: number;
  dailyPortionText: string;
  halfDailyPortionText: string;
  subscriptionSummary?: {
    baseDiet: 'WLP' | 'Growth' | "Maintenance";
    subscriptionType: SubscriptionProductType;
    keyProductQuantity: number;
    scheduleInWeeks: number
  };
  feedingsPerDay: 2;
  portionPerMeal: number;
  portionPerMealText: string;
  currentWeight: { date: IsoDateString; bcs: null | number; weight: number };
  totalLossPercentage: number;
  firstMonthLossForecast: [number, number, number, number];
};

export enum SubscriptionProductType {
  FullDietPlan = 'full-diet-plan',
  HalfDietPlan = 'half-diet-plan',
  TopperPlan = 'topper-plan',
  WeightLossPlan = 'weight-loss-plan',
  WeightLossMixedPlan = 'weight-loss-mixed-plan',
  SubscribeAndSave = 'subscribe&save',
}

export type PatientPhoto = {
  id: string;
  path: string;
  uploaded: string;
  date: string;
  weight: number;
  mimeType: string;
}
