import {FormControl, Stack} from '@mui/material';
import React from 'react';
import {InputLabelStyled, InputStyled} from '@/views/pages/update-weight/UpdateWeightPage.styled';
import {Units} from '@/types/common';

const KgInput = ({onChange, kgsValue }: { kgsValue: number, onChange(val: string, unit: Units): void; }) => {

  return <Stack spacing={2} direction={'row'}>
    <FormControl variant='outlined' fullWidth>
      <InputLabelStyled htmlFor='pounds'>Kilograms</InputLabelStyled>
      <InputStyled
        id={'outlined-weight-helper-text'}
        aria-describedby='outlined-weight-helper-text'
        onChange={({target}: any) => onChange(target?.value, Units.KG_DECIMAL)}
        type={'number'}
        inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxLength: 5, step: 0.1}}
        placeholder={'0'}
        value={kgsValue || ''}
      />
    </FormControl>
    {/*<FormControl variant='outlined' fullWidth>*/}
    {/*  <InputLabelStyled htmlFor='pounds'>Grams</InputLabelStyled>*/}
    {/*  <InputStyled*/}
    {/*    id={'outlined-weight-helper-text'}*/}
    {/*    aria-describedby='outlined-weight-helper-text'*/}
    {/*    onChange={({target}: any) => onChange(target.value, Units.GRAMS)}*/}
    {/*    type={'number'}*/}
    {/*    inputProps={{inputMode: 'numeric', pattern: '[0-9]*', maxLength: 3}}*/}
    {/*    placeholder={'0'}*/}
    {/*    value={gramsValue}*/}
    {/*  />*/}
    {/*</FormControl>*/}
  </Stack>
};

export default KgInput;
