import PageTitle from '@/components/page-title/PageTitle';
import { Container, Stack } from '@mui/material';
import HintBox from '@/components/hint-box/HintBox';
import { useConst } from '@fluentui/react-hooks';
import WidgetAccordion from '@/components/widget-accordion/WidgetAccordion';
import RecommendedDietWidget from '@/views/common/widgets/RecommendedDietWidget';
import PortionWidget from '@/views/common/widgets/PortionWidget';
import TreatsWidget from '@/views/common/widgets/TreatsWidget';

const FeedingInstructionPage = () => {
  const accordionItems = useConst([
    PortionWidget(),
    RecommendedDietWidget(),
    TreatsWidget(),
  ]);

  return <Container maxWidth={false}>
    <PageTitle title={'Feeding Instructions'} />
    <Stack spacing={2}>
      <HintBox titlePrefix={'🍟'} storeKey={'feeding_instruction'}>
        It’s really important to avoid snacks and table scraps.
        If you’re a treat giver, try to use Cheerios or a kibble piece as a treat.
      </HintBox>
      <WidgetAccordion items={accordionItems} />
    </Stack>
  </Container>;
};

export default FeedingInstructionPage;
