import PageTitle from '@/components/page-title/PageTitle';
import { Box, Container, Typography } from '@mui/material';
import {
  MIContentStyled,
  MIStyled,
  SeeMoreBtnStyled,
} from '@/views/pages/messages/MessagesPage.styled';
import dayjs from 'dayjs';
import { useNotifications } from '@/app/hooks';
import { useEffect } from 'react';

const MessagesPage = () => {
  const { notifications, readAllNotifications, paging, nextNotifications } = useNotifications();

  useEffect(() => {
    const timer = setTimeout(() => {
      readAllNotifications();
    }, 5 * 1000);
    return () => clearTimeout(timer);
  }, [readAllNotifications]);

  const isSeeMore = paging ? (paging.limit + paging.offset <= paging.total) : false;

  return <Container maxWidth={false}>
    <PageTitle title={'Messages'} />
    <Box pb={4}>
      {
        notifications.map((message: any) => (
          <MIStyled spacing={1} key={message.id}>
            <Typography variant={'boldTitle'}>{dayjs(message.createdAt).format('MM/DD/YYYY')}</Typography>
            <MIContentStyled>{message.message}</MIContentStyled>
          </MIStyled>
        ))
      }
      {
        isSeeMore && <Box display={'flex'} justifyContent={'center'} mt={2}>
          <SeeMoreBtnStyled variant='extended' onClick={nextNotifications}>
            See More
          </SeeMoreBtnStyled>
        </Box>
      }
    </Box>
  </Container>
};

export default MessagesPage;
