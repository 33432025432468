import {createSlice} from "@reduxjs/toolkit";
import {api} from "./api";

const TOKEN_KEY = "_token";
export interface AuthState {
    token: string | null;
    loggedOut: boolean
}

const initialState: AuthState = {
    token: localStorage.getItem(TOKEN_KEY),
    loggedOut: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(api.endpoints.login.matchFulfilled, (state, result) => {
            state.token = result.payload.token;
            localStorage.setItem(TOKEN_KEY, state.token)
        })
        builder.addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
            localStorage.removeItem(TOKEN_KEY)
            state.loggedOut = true;
            state.token = null;
        })
        builder.addMatcher(api.endpoints.dietPlans.matchRejected, (state, {payload: response}) => {
            if (response?.status === 401) {
                localStorage.removeItem(TOKEN_KEY)
                state.loggedOut = true;
                state.token = null;
            }
        })
        builder.addMatcher(api.endpoints.dietPlans.matchFulfilled, (state, {payload: response}) => {
            if (response.length === 0) {
                localStorage.removeItem(TOKEN_KEY)
                state.loggedOut = true;
                state.token = null;
            }
        })
    }
})
