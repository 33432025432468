import React from 'react';
import { styled } from '@mui/material';
function Image(
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) {
  return <img alt={''} {...props} />;
}

export const SliderImageStyled = styled(Image)`
  width: 100%;
  aspect-ratio: 4 / 5;
  object-fit: cover;
`;
