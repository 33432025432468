import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import {useDogName} from "@/app/hooks";

export function UpdateWeightNoteSlide() {
  const dogName = useDogName()
  return (
    <Box
      sx={{
        width: '100%',
        aspectRatio: '4/5',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#f8f8f8',
        gap: 4,
      }}
    >
      <Typography variant={'h5'} textAlign={'center'}>
        Update {dogName}'s weight to upload a photo
      </Typography>
      <Button color={'primary'} href={'/update-weight'} variant={'contained'} data-action={'photo_go_update_weight'}>
        Update weight
      </Button>
    </Box>
  );
}
