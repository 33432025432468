import { Box, Stack, Typography } from '@mui/material';
import Carrot from '@/assets/icons/carrot.svg';
import { useDietPlans } from '@/app/hooks';

const NutritionPlanWidget = () => {
  const { selectedDiet } = useDietPlans();

  return <Stack spacing={2}>
    <Box>
      <Typography>Diet recommendation:</Typography>
      <Typography variant={'subtitle2'}>Weight Loss</Typography>
    </Box>
    <Box>
      <Typography>Calories per day:</Typography>
      <Typography variant={'subtitle2'}>{selectedDiet?.recommendedCalories} kcal.</Typography>
    </Box>
    <Box>
      <Typography>Feedings per day:</Typography>
      <Typography variant={'subtitle2'}>{selectedDiet?.feedingsPerDay}</Typography>
    </Box>
  </Stack>;
};
export default () => ({
  key: 'nutrition-plan',
  label: 'Nutrition Plan',
  icon: Carrot,
  widget: <NutritionPlanWidget />,
});
