import { useCallback, useId } from 'react';

function useScrollTo(): [string, () => void] {
  const id = useId();
  const handleScroll = useCallback(() => {
    const element = document.getElementById(id);
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  }, [id]);

  return [id, handleScroll];
}

export default useScrollTo;
