import React from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Box, Container } from '@mui/material';

import { api } from '@/app/api';
import { useDietPlans } from '@/app/hooks';
import HintBox from '@/components/hint-box/HintBox';
import PageTitle from '@/components/page-title/PageTitle';
import { PageLoader } from '@/components/activity/page-loader';
import { PhotoSlider } from './PhotoSlider';
import dayjs from 'dayjs';

export function GalleryPage() {
  const { selectedDiet } = useDietPlans();
  const [deletePhoto] = api.useDeletePatientPhotoMutation();
  const [upload] = api.useUploadPhotoMutation();
  const { data: photos } = api.usePatientPhotosQuery(
    selectedDiet?.patient.id ?? skipToken
  );

  const canUpload =
    new Date(selectedDiet?.currentWeight?.date ?? 0).getTime() >
    new Date(photos?.at(-1)?.date ?? 0).getTime();
  const canUpdateWeight =
    selectedDiet?.currentWeight?.date < dayjs().format('YYYY-MM-DD');

  return (
    <Container>
      <PageTitle title={'Gallery'} />
      <HintBox titlePrefix={'🐕'} storeKey={'gallery_hint'}>
        Save photos and track progress as time-lapse
      </HintBox>
      <Box
        sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
      >
        {photos && selectedDiet ? (
          <PhotoSlider
            photos={photos}
            currentWeight={selectedDiet.currentWeight}
            deletePhoto={deletePhoto}
            uploadPhoto={upload}
            patientId={selectedDiet.patient.id}
            canUpload={canUpload}
            canUpdateWeight={canUpdateWeight}
          />
        ) : (
          <PageLoader />
        )}
      </Box>
    </Container>
  );
}
