import { Bar, BarChart, Label, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { useMemo } from 'react';
import { Props } from 'recharts/types/component/Label';
import Power from '@/assets/icons/power.svg';
import { useTrackerReport } from '@/app/hooks';
import dayjs from 'dayjs';
import chartUtils from '@/helpers/chartUtils';
import {round2} from "@/helpers/weightConverter";

const {min, max} = Math
const transformToChart = (reports: any[]): {name: string, value: number}[] => {
  const today = dayjs();
  const chart: any = [];

  for (let i = 0; i < 7; i++) {
    const day = today.subtract(i, 'days');
    const weekDay = day.format('ddd');
    const found = reports.find(item => dayjs(item.date).isSame(day, 'day'));
    chart.unshift({
      name: weekDay,
      value: found ? found.totalSteps : 0,
    });
  }
  return chart;
};

const ActivityLabel = (props: Props) => {
  const { x, y, value = 0, name, width, height, offset } = props;
  const fontSize = min(max(14, Number(width)/3), 20)
  const dx = Number(width)/2;
  return <text
    name={name} x={x} y={y} dx={dx}
    width={width} height={height} offset={offset} fill={'white'}
    className='recharts-text recharts-label' textAnchor='middle'
  >
    {
      value
        ? <tspan x={x} dy='-10px' fontSize={fontSize} fontWeight={'bold'}>{round2(value as number / 1000)}K</tspan>
        : null
    }
  </text>;
};

const ActivityWidget = () => {
  const { data } = useTrackerReport();

  const chart = useMemo(() => transformToChart(data?.data || []), [data]);

  const lastUpdateText = useMemo(() => chartUtils.lastUpdateLabel(data?.data), [data]);

  return <ResponsiveContainer width={'100%'} height={250}>
    <BarChart data={chart}>
      <XAxis
        dataKey='name'
        axisLine={false}
        tickLine={false}
        style={{ fill: 'white', fontWeight: 'bold' }}
      />
      <YAxis
        domain={([dataMin, dataMax]) => [dataMin, dataMax * 1.2 ]}
        axisLine={false}
        tickLine={false}
        spacing={0}
        offset={0}
        dy={0}
        width={1}
      >
        <Label position={'insideTopLeft'} fill={'white'} dy={-10}>{lastUpdateText}</Label>
      </YAxis>
      <Bar
        type='monotone'
        dataKey='value'
        fill={'#A0D5BB'}
        radius={4}
        label={<ActivityLabel />}
      />
    </BarChart>
  </ResponsiveContainer>;
};

export default () => ({
  key: 'activity-chart',
  label: 'Activity Chart',
  icon: Power,
  outlinedIcon: true,
  sx: {
    '.MuiAccordionSummary-root': {
      backgroundColor: '#A0D5BB',
    },
    '.MuiAccordionDetails-root': {
      backgroundColor: '#142A3D',
    },
  },
  widget: <ActivityWidget />,
});
