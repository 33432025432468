import RoundedIconStyled from '@/components/rounded-icon/RoundedIcon.styled';

interface RoundedIconProps {
  icon: string;
  alt?: string;
}

const RoundedIcon = ({ icon, alt }: RoundedIconProps) => {
  return <RoundedIconStyled>
    <img src={icon} alt={alt} />
  </RoundedIconStyled>;
};

export default RoundedIcon;
