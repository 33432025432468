import PageTitle from '@/components/page-title/PageTitle';
import { Container, Stack } from '@mui/material';
// import HintBox from '@/components/hint-box/HintBox';
import { useConst } from '@fluentui/react-hooks';
import WidgetAccordion from '@/components/widget-accordion/WidgetAccordion';
import SleepWidget from '@/views/common/widgets/SleepWidget';
import WeightLossTrendWidget from '@/views/common/widgets/WeightLossTrendWidget';
import ActivityWidget from '@/views/common/widgets/ActivityWidget';
// import {useDogName} from "@/app/hooks";

const InsightPage = () => {
  const accordionItems = useConst([
    WeightLossTrendWidget(),
    SleepWidget(),
    ActivityWidget(),
  ]);
  // const dogName = useDogName();

  return <Container maxWidth={false}>
    <PageTitle title={'Insights'} />
    <Stack spacing={2}>
      {/*<HintBox titlePrefix={'⚖️'} storeKey={'insight'}>*/}
      {/*  Based on {dogName}’s current weight as compared to his weight loss trend, {dogName} is not losing enough weight.*/}
      {/*</HintBox>*/}
      <WidgetAccordion items={accordionItems} />
    </Stack>
  </Container>;
};

export default InsightPage;
