import {Box, Container, Stack, Typography} from '@mui/material';
import HintBox from '@/components/hint-box/HintBox';
import { useConst } from '@fluentui/react-hooks';
import PageTitle from '@/components/page-title/PageTitle';
import WidgetAccordion from '@/components/widget-accordion/WidgetAccordion';
import { WidgetItemType } from '@/components/widget-accordion/types';
import WeightLossWidget from '@/views/common/widgets/WeightLossWidget';
import EstimatedDurationWidget from '@/views/common/widgets/EstimatedDurationWidget';
import NutritionPlanWidget from '@/views/common/widgets/NutritionPlanWidget';
import ExercisePlanWidget from '@/views/common/widgets/ExercisePlanWidget';
import {ProgressBar} from "@/components/activity/ProgressBar";
import {useWeightLossProgress} from "@/app/hooks";

export function OverviewPage() {
  const accordionItems = useConst<WidgetItemType[]>([
    WeightLossWidget(),
    EstimatedDurationWidget(),
    NutritionPlanWidget(),
    ExercisePlanWidget(),
  ]);

  const progress = useWeightLossProgress()

  return <Container maxWidth={false}>
    <PageTitle title={'Overview'} />
    <Stack spacing={2}>
      <HintBox titlePrefix={'🎯'} storeKey={'overview_hint'}>
        We target a safe and effective weight loss rate of -1% per week.
        Based on actual results, we dynamically adjust the plan to stay on track.
      </HintBox>
      <Box>
        <Typography fontWeight={800} fontSize={'11'} lineHeight={2}>Weight loss progress</Typography>
        <ProgressBar progress={progress} />
      </Box>
      <WidgetAccordion items={accordionItems} />
    </Stack>
  </Container>;
}
