import { Box, Stack, Typography } from '@mui/material';
import Diet from '@/assets/icons/diet.svg';

const RecommendedDietWidget = () => {
  return <Stack spacing={2}>
    <Box>
      <Typography>Food format:</Typography>
      <Typography variant={'subtitle2'}>Fresh</Typography>
    </Box>
    <Box>
      <Typography>Formulation Function:</Typography>
      <Typography variant={'subtitle2'}>Weight Loss</Typography>
    </Box>
    <Box>
      <Typography>Primary Protein:</Typography>
      <Typography variant={'subtitle2'}>Pork Tenderloin</Typography>
    </Box>
  </Stack>;
};

export default () => ({
  key: 'recommended-diet',
  label: 'Recommended Diet',
  icon: Diet,
  widget: <RecommendedDietWidget />,
});
