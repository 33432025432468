import Moon from '@/assets/icons/moon.svg';
import { Bar, BarChart, Label, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import React, { useMemo } from 'react';
import { Props } from 'recharts/types/component/Label';
import { useTrackerReport } from '@/app/hooks';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import chartUtils from '@/helpers/chartUtils';
import {round2} from "@/helpers/weightConverter";

dayjs.extend(isYesterday);
dayjs.extend(isToday);
const {min, max} = Math

const HoursLabel = (props: Props) => {
  const { x, y, value, name, width, height, offset } = props;
  const fontSize = min(max(13, Number(width)/3), 20)
  const dx = Number(width)/2;
  return <text
    name={name} x={x} y={y} dx={dx}
    width={width} height={height} offset={offset} fill={'white'}
    className='recharts-text recharts-label' textAnchor='middle'
  >
    {
      value ? <tspan x={x} dy='-10px' fontSize={fontSize} fontWeight={'bold'}>{value} hr</tspan> : null
    }
  </text>;
};

const transformToChart = (reports: any[]): {name: string, value: number}[] => {
  const today = dayjs();
  const chart: any = [];

  for (let i = 0; i < 7; i++) {
    const day = today.subtract(i, 'days');
    const weekDay = day.format('ddd');
    const found = reports.find(item => dayjs(item.date).isSame(day, 'day'));
    chart.unshift({
      name: weekDay,
      value: found ? round2(found.sleepRecords / 60, 10) : 0,
    });
  }
  return chart;
};

const SleepWidget = () => {
  const { data } = useTrackerReport();

  const chart = useMemo(() => transformToChart(data?.data || []), [data]);
  const max = chart.reduce((max, {value}) => Math.max(value, max), 0)

  const lastUpdateText = useMemo(() => chartUtils.lastUpdateLabel(data?.data), [data]);

  return <ResponsiveContainer width={'100%'} height={250}>
    <BarChart data={chart} height={200} width={200}>
      <XAxis
        dataKey='name'
        axisLine={false}
        tickLine={false}
        style={{ fill: 'white', fontWeight: 'bold' }}
      >
      </XAxis>
      <YAxis
        domain={([dataMin, dataMax]) => [dataMin, dataMax + (dataMax / 6)]}
        axisLine={false}
        tickLine={false}
        spacing={10}
        offset={0}
        ticks={[0, max * 1.2]}
        dy={0}
        width={1}
      >
        <Label position={'insideTopLeft'} fill={'white'}>{lastUpdateText}</Label>
      </YAxis>
      <Bar
        type='monotone'
        dataKey='value'
        fill={'#A9A3D3'}
        radius={4}
        label={<HoursLabel />}
      />
    </BarChart>
  </ResponsiveContainer>;
};

export default () => ({
  key: 'sleep-chart',
  label: 'Sleep Chart',
  icon: Moon,
  outlinedIcon: true,
  sx: {
    '.MuiAccordionSummary-root': {
      backgroundColor: '#B49AFF',
    },
    '.MuiAccordionDetails-root': {
      backgroundColor: '#100556',
    },
  },
  widget: <SleepWidget />,
});
