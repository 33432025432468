import { Box, Fab, Paper, styled, Typography } from '@mui/material';
import { common } from '@mui/material/colors';

export const MWrapperStyled = styled(Box)`
`;

export const MIStyled = styled(Paper)`
  background: ${props => props.theme.palette.secondary.main};
  padding: 16px 20px;
  margin-bottom: 10px;
`;

export const MIContentStyled = styled(Typography)`
  margin-top: 8px;
`;

export const SeeMoreBtnStyled = styled(Fab)`
  background: ${common.white};
  border: 1px solid ${props => props.theme.palette.primary.main};
  font-size: 12px;
  width: 120px;
  font-weight: bold;
`;
