import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoundedIcon from '@/components/rounded-icon/RoundedIcon';
import { WidgetItemType } from '@/components/widget-accordion/types';
import useScrollTo from '@/hooks/useScrollTo';

type WidgetItemProps = {
  item: WidgetItemType;
  isExpanded: boolean;
  onChange(key: string): any
}
const WidgetItem = ({ item, isExpanded, onChange }: WidgetItemProps,
) => {
  const [sectionId, scrollToSection] = useScrollTo();

  const handleScrollAnimation = () => {
    if (isExpanded) {
      scrollToSection();
    }
  };

  return (
    <Box sx={item.sx} id={sectionId}>
      <Accordion
        expanded={isExpanded}
        onChange={onChange(item.key)}
        TransitionProps={{ unmountOnExit: true, onEntered: handleScrollAnimation }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={item.key}>
          {
            item.outlinedIcon
              ? <img src={item.icon} alt={item.label} />
              : <RoundedIcon icon={item.icon} alt={item.label} />
          }
          <Typography ml={2} variant={'boldTitle'}>
            {item.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {item.widget}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default WidgetItem;
