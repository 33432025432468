import { Outlet } from 'react-router-dom';
import Header from './header/Header';
import ChooseProgram from '@/views/layout/choose-program/ChooseProgram';
import { Box } from '@mui/material';

export function MainLayout() {
  return (
    <Box display={'flex'} justifyContent={'center'}>
      <Box maxWidth={768} flex={1}>
        <Header />
        <main>
          <ChooseProgram />
          <Outlet />
        </main>
      </Box>
    </Box>
  );
}
