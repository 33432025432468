import {Button, Container, Stack, Typography} from '@mui/material';
import PageTitle from '@/components/page-title/PageTitle';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import BarChart from '@mui/icons-material/BarChart';
import {useDogName} from "@/app/hooks";

export function ThankYouPage() {

  const name = useDogName()

  return <Container>
    <PageTitle title={'Thank you'}/>
    <Typography variant={'subtitle1'} lineHeight={2}>
      You have successfully updated the {name}'s progress.
    </Typography>

    <Stack gap={3} marginTop={3} justifyContent={'center'}>
      <Button href={'/gallery'} sx={{p: 6, borderRadius: 1}} variant={'outlined'} color={'primary'}>
        <Stack gap={3} alignItems={'center'}>
          <PhotoCamera fontSize={'large'}/>
          <Typography>
            Take a picture
          </Typography>
        </Stack>
      </Button>
      <Button href={'/insight'} sx={{p: 6, borderRadius: 1}} variant={'outlined'} color={'primary'}>
        <Stack gap={3} alignItems={'center'}>
          <BarChart fontSize={'large'} />
          <Typography>
            Insights
          </Typography>
        </Stack>
      </Button>
    </Stack>

  </Container>;
}
