import { Outlet } from 'react-router-dom';
import { Box, Link, Paper, Stack } from '@mui/material';
import logo from '@/assets/images/logo.png';

export function UnAuthenticatedLayout() {
  return (
    <Stack bgcolor={'#E5EDF3'} height={'100vh'} alignItems={'center'}>
      <Box flex={1} />
      <Stack flexDirection={'column'} margin={5}>
        <Box alignSelf={'center'}>
          <img src={logo} alt={'breed science'} width={100} />
        </Box>

        <Outlet />

        <Paper sx={{ backgroundColor: '#FF9E77', p: 2, mt: 5 }} elevation={2}>
          Get a magic link for a password free sign in or:{' '}
          <Link href={'https://breedscience.com/account'}>
            Sign in manually instead
          </Link>
        </Paper>
      </Stack>
      <Box flex={3} />
    </Stack>
  );
}
