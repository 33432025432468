import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {api} from './api';
import {DietPlan} from "@/types/common";

export interface CommonState {
  selectedDiet: DietPlan;
  diets: any;
  updateWeightForm: {
    weightLb: number;
  };
  notifications: any[];
  notificationOffset: number;
}

const getCachedDiet = () => {
  try {
    const diet = localStorage.getItem('selectedDiet');
    if (diet) {
      return JSON.parse(diet);
    }
  } catch {
    return null
  }
  return null;
}

const initialState: CommonState = {
  selectedDiet: getCachedDiet(),
  diets: null,
  updateWeightForm: {
    weightLb: 0,
  },
  notifications: [],
  notificationOffset: 0,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    selectDiet(state, action: PayloadAction<DietPlan>) {
      state.selectedDiet = action.payload;
      localStorage.setItem('selectedDiet', JSON.stringify(action.payload));
    },
    setWeight(state, action: PayloadAction<number>) {
      state.updateWeightForm.weightLb = action.payload;
    },
    setNotificationOffset(state, action: PayloadAction<number>) {
      state.notificationOffset = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(api.endpoints.dietPlans.matchFulfilled, (state, result) => {
      state.diets = result.payload;
      if (!state.selectedDiet) {
        state.selectedDiet = result.payload[0];
      } else {
        const updatedDiet = result.payload.find((diet: any) => diet.patient.id === state.selectedDiet.patient.id) ?? result.payload[0];
        if (updatedDiet) {
          state.selectedDiet = updatedDiet;
          localStorage.setItem('selectedDiet', JSON.stringify(updatedDiet));
        }
      }
    });
    builder.addMatcher(api.endpoints.notifications.matchFulfilled, (state, result) => {
      const newFetched = result.payload.data;
      const existing = [...state.notifications];
      newFetched.forEach((newNotif: any) => {
        const i = existing.findIndex(oldNotif => oldNotif.id === newNotif.id)
        if (i >= 0) {
          existing[i] = newNotif;
        } else {
          existing.push(newNotif);
        }
      })
      state.notifications = existing;
    });
  },
});
