const kgToPound = (weight: number): number => {
  return weight * 2.205;
};

const ounceToPound = (pound: number, ounces: number = 0): number => {
  return pound + (ounces * 0.0625);
};

const poundToOunce = (pound: number): [number, number] => {
  const ounces = 16 * pound;
  const onlyOunce = Math.round(ounces % 16);
  return [Math.round((ounces - onlyOunce) / 16), onlyOunce];
};

const poundsToKgAndGrams = (pound: number) => {
  const kgWhole = round2(pound / 2.205, 1000);
  const kg = Math.floor(kgWhole);
  const grams = Math.round((kgWhole - kg) * 1000)
  return [kg, grams, round2(kgWhole, 100)]
}

export const round2 = (num: number, precision = 100) => Math.round(num * precision) / precision;
export default {
  kgToPound,
  ounceToPound,
  poundToOunce,
  poundsToKgAndGrams,
}
