import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MobileStepper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import SwipeableViews from 'react-swipeable-views';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import { SliderImageStyled } from './SliderImageStyled';
import { PhotoSliderProps } from './types';
import { NewImageUploadSlide } from './NewImageUploadSlide';
import { PatientPhoto } from '@/types/common';
import { round2 } from '@/helpers/weightConverter';
import { UpdateWeightNoteSlide } from '@/views/pages/gallery/UpdateWeightNoteSlide';
import { useMount } from '@fluentui/react-hooks';

export function PhotoSlider({
  photos,
  currentWeight,
  deletePhoto,
  uploadPhoto,
  patientId,
  canUpload,
  canUpdateWeight,
}: PhotoSliderProps) {
  const [index, setIndex] = useState(0);

  const totalPhotos = photos.length;

  const slides = useMemo(() => {
    const children = photos.map((i, index) => (
      <div key={index}>
        <SliderImageStyled src={`/${i.path}`} />
      </div>
    ));
    if (canUpload) {
      children.push(
        <div key={'last'}>
          <NewImageUploadSlide
            uploadPhoto={uploadPhoto}
            patientId={patientId}
            currentWeight={currentWeight}
          />
        </div>
      );
    } else if (canUpdateWeight) {
      children.push(
        <div key={'last'}>
          <UpdateWeightNoteSlide />
        </div>
      );
    }
    return children;
  }, [
    photos,
    canUpload,
    canUpdateWeight,
    currentWeight,
    patientId,
    uploadPhoto,
  ]);
  const totalSlides = slides.length;

  useMount(() => {
    setTimeout(() => {
      if (canUpload && !canUpdateWeight) {
        setIndex(totalSlides - 1);
      } else if (totalPhotos > 0) {
        setIndex(totalPhotos - 1);
      }
    }, 300);
  });

  useEffect(() => {
    if (index >= totalSlides) {
      setIndex(totalSlides - 1);
    }
  }, [totalSlides, index]);

  return (
    <Stack
      marginBottom={2}
      bgcolor={'secondary.main'}
      maxWidth={420}
      width={'100%'}
    >
      <Toolbar
        sx={{
          height: 50,
          pl: 2,
          backgroundColor: 'secondary.main',
        }}
      >
        <CurrentPhotoTitle
          photo={photos[index]}
          currentWeight={currentWeight}
        />
        <Box flex={1} />
        <ShareButton url={photos[index]?.path} />
        <ShowMoreMenu
          onDeletePhoto={() => deletePhoto(photos![index].id!)}
          disabled={index >= photos.length}
        />
      </Toolbar>
      <SwipeableViews
        enableMouseEvents
        index={index}
        onChangeIndex={setIndex}
        style={{ width: '100%', aspectRatio: '4/5', overflow: 'hidden' }}
        children={slides}
      />
      <MobileStepper
        variant="text"
        activeStep={index}
        steps={totalSlides}
        position="static"
        style={{ padding: '8px 0px' }}
        nextButton={
          <Button
            size="small"
            onClick={() => setIndex((i) => i + 1)}
            disabled={index + 1 >= totalSlides}
          >
            {index + 1 < totalPhotos
              ? dayjs(new Date(photos[index + 1].date)).format('MMM D')
              : canUpload
              ? 'new'
              : canUpdateWeight
              ? 'more'
              : ''}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={() => setIndex((i) => i - 1)}
            disabled={index === 0}
          >
            <KeyboardArrowLeft />
            {photos[index - 1]
              ? dayjs(new Date(photos[index - 1].date)).format('MMM D')
              : ''}
          </Button>
        }
      />
    </Stack>
  );
}

function CurrentPhotoTitle({
  photo = undefined as PatientPhoto | undefined,
  currentWeight = { weight: 0, date: '' },
}) {
  return (
    <Typography variant={'h6'}>
      {dayjs(new Date(photo?.date ?? currentWeight.date)).format('MMMM D')}
      {', '}
      {round2(photo?.weight ?? currentWeight.weight)}
      {'lb'}
    </Typography>
  );
}

export function ShareButton({ url }: { url?: string }) {
  const cannotShare = !useMemo(() => {
    if ('canShare' in navigator && 'share' in navigator) {
      return navigator.canShare({ url: window.location.href });
    }
    return false;
  }, []);

  if (cannotShare) {
    return null;
  }

  const share = () => {
    if (!url) {
      return;
    }
    const link = new URL(window.location.href);
    link.pathname = url;
    link.search = '';
    console.log(link.toString())
    navigator
      .share({
        url: link.toString(),
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  return (
    <IconButton disabled={!url} onClick={share} data-action={`photo_share`}>
      <ShareIcon  data-action={`photo_share`} />
    </IconButton>
  );
}
export function ShowMoreMenu({
  onDeletePhoto = () => null as unknown,
  disabled = false,
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const handleDeletePhoto = () => {
    closeMenu();
    onDeletePhoto();
  };

  return (
    <div>
      <IconButton onClick={openMenu} disabled={disabled}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem data-action={`photo_delete`} onClick={handleDeletePhoto}>Delete Photo</MenuItem>
      </Menu>
    </div>
  );
}
