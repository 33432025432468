import { Box, Stack, Typography } from '@mui/material';
import Dog2 from '@/assets/icons/dog2.svg';

const ExercisePlanWidget = () => {
  return <Stack spacing={2}>
    <Box>
      <Typography>Steps per day:</Typography>
      <Typography variant={'subtitle2'}>6000</Typography>
    </Box>
    <Box>
      <Typography>Sleep per night:</Typography>
      <Typography variant={'subtitle2'}>8 hours</Typography>
    </Box>
  </Stack>;
};
export default () => ({
  key: 'exercise-plan',
  label: 'Exercise Plan',
  icon: Dog2,
  widget: <ExercisePlanWidget  />,
});
