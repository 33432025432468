import {
  DateView,
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  usePickerLayout,
} from '@mui/x-date-pickers';
import React from 'react';
import { Dayjs } from 'dayjs';
import { customColors } from '@/theme';
import { alpha } from '@mui/material';
import { common } from '@mui/material/colors';

const pickerLayoutStyles = {
  backgroundColor: customColors.darkBlue,
  '.MuiDateCalendar-root': {
    maxWidth: '400px',
    width: '100%',
  },
  '.MuiDayCalendar-weekContainer:not(:last-child)': {
    borderBottom: `1px solid ${alpha(common.white, 0.1)}`,
  },
  '.MuiPickersLayout-actionBar': {
    display: 'none',
  },
  '.MuiDayCalendar-weekDayLabel': {
    color: common.white,
    width: '50px',
  },
  '.MuiDayCalendar-header': {
    borderBottom: `1px solid ${alpha(common.white, 0.1)}`,
  },
  '.dayBadge': {
    position: 'relative',
    '.dayBadgeIcon': {
      width: '5px',
      height: '5px',
      borderRadius: '10px',
      background: 'red',
      position: 'absolute',
      right: '13px',
      top: '7px',
    },
  },
  '.MuiPickersDay-root': {
    backgroundColor: 'transparent',
    color: common.white,
    fontSize: '16px',
    marginLeft: '9px',
    marginRight: '9px',
    marginBottom: '15px',
    '&.MuiPickersDay-today': {
      border: `1px solid ${alpha(customColors.darkerBlue, 0.5)}`,
    },
    '&.Mui-disabled': {
      color: customColors.lightBlue,
    },
    '&:hover:not(.Mui-disabled):not(.Mui-selected)': {
      backgroundColor: alpha(customColors.darkerBlue, 0.5),
    },
    '&:focus.Mui-selected,&.Mui-selected': {
      backgroundColor: common.white,
      color: customColors.darkBlue,
    },
  },
  '.MuiPickersCalendarHeader-root, .MuiPickersArrowSwitcher-button:not(.Mui-disabled), .MuiPickersMonth-monthButton:not(.disabled)': {
    color: common.white,
  },
  '.MuiPickersCalendarHeader-root': {
    marginBottom: '12px',
    '.MuiPickersCalendarHeader-labelContainer': {
      fontSize: '1.2rem',
    },
  },
};
const StaticCalendarLayout = (props: PickersLayoutProps<Dayjs | null, Dayjs, DateView>) => {
  const { toolbar, tabs, content, actionBar } = usePickerLayout(props);

  return <PickersLayoutRoot className={pickersLayoutClasses.root} ownerState={props} sx={pickerLayoutStyles}>
    {toolbar}
    <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
      {tabs}
      {content}
    </PickersLayoutContentWrapper>
    {actionBar}
  </PickersLayoutRoot>;
};

export default StaticCalendarLayout;
