import { Box, Stack, Typography } from '@mui/material';
import Calendar from '@/assets/icons/calendar.svg';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useDietPlans } from '@/app/hooks';

const EstimatedDurationWidget = () => {
  const {selectedDiet} = useDietPlans();

  const period = useMemo(() => dayjs(selectedDiet?.finishOn).diff(selectedDiet?.startOn, 'weeks'), [selectedDiet]);
  const endMonth = useMemo(() => dayjs(selectedDiet?.finishOn).format('MMMM YYYY'), [selectedDiet]);
  return <Stack spacing={2}>
    <Box>
      <Typography>Ideal weight loss rate:</Typography>
      <Typography variant={'subtitle2'}>1% per week</Typography>
    </Box>
    <Box>
      <Typography>Program duration:</Typography>
      <Typography variant={'subtitle2'}>{period} weeks</Typography>
    </Box>
    <Box>
      <Typography>Finishing month:</Typography>
      <Typography variant={'subtitle2'}>{endMonth}</Typography>
    </Box>
  </Stack>;
};
export default () => ({
  key: 'estimated-duration',
  label: 'Estimated Duration',
  icon: Calendar,
  widget: <EstimatedDurationWidget />,
});
