import { HintBoxStyled } from '@/components/hint-box/HintBox.styled';
import CloseIcon from '@mui/icons-material/Close';
import { CloseButtonStyled } from '@/views/layout/header/Header.styled';
import React, { ReactNode } from 'react';
import useLocalStorage from '@/hooks/useLocalStorage';
import { Box, Typography } from '@mui/material';

interface HintBoxProps {
  titlePrefix: string;
  storeKey: string;
  children: ReactNode;
}

const HintBox = ({ titlePrefix, storeKey, children }: HintBoxProps) => {
  const [isHintClosed, setValue] = useLocalStorage(storeKey, false);

  const handleClose = () => {
    setValue(true);
  };

  if (isHintClosed) {
    return null;
  }

  return <HintBoxStyled>
    <CloseButtonStyled
      color='secondary'
      onClick={handleClose}
      aria-label='close'
      data-action={`hint_close_${storeKey}`}
      sx={{ position: 'absolute', right: '1px', top: '1px' }}
    >
      <CloseIcon sx={{ fontSize: 25, color: (theme) => theme.palette.primary.main }} data-action={`hint_close_${storeKey}`} />
    </CloseButtonStyled>
    <Box>
      <Typography variant={'subtitle1'}>
        <span style={{ fontSize: 30 }}>{titlePrefix} </span>
        Hint:
      </Typography>
      <Typography>
        {children}
      </Typography>
    </Box>
  </HintBoxStyled>
}

export default HintBox;
