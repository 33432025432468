import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

class CustomString extends String {
  charAt(_: number): string {
    return this.valueOf();
  }
}

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const customWeekDays = weekDays.map((day) => new CustomString(day) as string);

class WeekDayAdapter extends AdapterDayjs {
  getWeekdays = (): string[] => customWeekDays;
}

export default WeekDayAdapter;
