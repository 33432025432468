import React, { useMemo } from 'react';
import { AppBar, FormControl, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { customColors } from '@/theme';
import { useDietPlans } from '@/app/hooks';
import { useLocation } from 'react-router-dom';

export default function ChooseProgram() {
  const { pathname } = useLocation();
  const { diets, chooseDiet, selectedDiet } = useDietPlans();

  const handleChoose = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    const diet = diets.find((item) => item.patient.id === value);
    if (diet) {
      chooseDiet(diet);
    }
  };

  const menuItems = useMemo(() => diets
    ?.map((diet: any) => ({ key: diet.patient.id, label: diet.patient.dogName, })) || [],
    [diets]
  );

  const disableSwitch = menuItems.length <= 1 || pathname === '/weight-calendar'

  return (
    <AppBar position={'sticky'}>
      <Paper sx={{ backgroundColor: customColors.darkBlue, height: '50px' }}>
        <FormControl fullWidth>
          {
            !menuItems.length ? null
              : <Select
                value={selectedDiet.patient.id}
                renderValue={() => <Typography variant={'boldTitle'}>{selectedDiet.patient.dogName}'s Weight Loss
                  Program</Typography>}
                onChange={handleChoose}
                variant={'filled'}
                MenuProps={{
                  marginThreshold: 0,
                  PaperProps: {
                    style: {
                      backgroundColor: customColors.darkBlue,
                      color: common.white,
                      borderRadius: 0,
                    },
                  },
                }}
                disabled={disableSwitch}
                sx={{
                  height: '50px',
                  color: `${common.white} !important`,
                  '.Mui-disabled': {
                    // override defaults
                    '-webkit-text-fill-color': `${common.white}`,
                  },
                  '.MuiSelect-filled': {
                    padding: '10px 16px 8px !important',
                  },
                  'svg': {
                    display: disableSwitch ? 'none' : 'block',
                    color: common.white,
                  },
                }}
              >
                {
                  menuItems.map((item: any) => (
                    <MenuItem
                      key={item.key}
                      disableRipple
                      value={item.key}
                      sx={{ fontSize: '12px' }}
                      disabled={item.key === selectedDiet.patient?.id}
                    >
                      {item.label}
                    </MenuItem>
                  ))
                }
              </Select>
          }
        </FormControl>
      </Paper>
    </AppBar>
  );
}
