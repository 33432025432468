import { Stack } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { WidgetItemType } from '@/components/widget-accordion/types';
import WidgetItem from '@/components/widget-accordion/WidgetItem';
import { fireGTMEvent } from "@/helpers/gtag";

interface WidgetAccordionProps {
  items: WidgetItemType[];
}

const WidgetAccordion = ({ items }: WidgetAccordionProps) => {
  const [expanded, setExpanded] = useState<string[]>(items.filter(w => w.isOpen).map(w => w.key));

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      fireGTMEvent('widget_toggle', { id: panel })
      setExpanded(
        !isExpanded ? expanded.filter(pan => pan !== panel) : [...expanded, panel],
      );
    };

  return <Stack sx={{ pb: '20px' }} spacing={2}>
    {
      items.map(item => <WidgetItem
        key={item.key}
        item={item}
        onChange={handleChange}
        isExpanded={expanded.includes(item.key)}
      />)
    }
  </Stack>;
};

export default WidgetAccordion;
