import { Box, Stack, Typography } from '@mui/material';
import Dog1 from '@/assets/icons/dog.svg';
import { useDietPlans } from '@/app/hooks';

const WeightLossWidget = () => {
  const { selectedDiet } = useDietPlans();

  return <Stack spacing={2}>
    <Box>
      <Typography>Ideal weight:</Typography>
      <Typography variant={'subtitle2'}>{selectedDiet?.idealWeight} lbs</Typography>
    </Box>
    <Box>
      <Typography>Ideal body condition score:</Typography>
      <Typography variant={'subtitle2'}>5</Typography>
    </Box>
    <Box>
      <Typography>Total expected weight loss:</Typography>
      <Typography variant={'subtitle2'}>{selectedDiet?.totalLossPercentage}%</Typography>
    </Box>
  </Stack>;
};

export default () => ({
  key: 'weight-loss-goals',
  label: 'Weight Loss Goals',
  icon: Dog1,
  isOpen: true,
  widget: <WeightLossWidget />,
});
