import { Box, Stack, Typography } from '@mui/material';
import Bone from '@/assets/icons/bone.svg';

const TreatsWidget = () => {
  return <Stack spacing={2}>
    <Box>
      <Typography>Recommended treats per day:</Typography>
      <Typography variant={'subtitle2'}>No Treats</Typography>
    </Box>
    <Box>
      <Typography>*Also no dental treats during the weight loss program.</Typography>
    </Box>
    <Box>
      <Typography>**It’s really important to avoid snacks and table scraps.
        If you’re a treat giver, try to use Cheerios or a kibble piece as a treat.</Typography>
    </Box>
  </Stack>;
};

export default () => ({
  key: 'threats',
  label: 'Treats',
  icon: Bone,
  widget: <TreatsWidget />,
});
