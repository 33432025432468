import { Box, IconButton, ListItem, styled } from '@mui/material';

export const MenuItemStyled = styled(ListItem)`
  cursor: pointer;
  padding: 23px 0;
  text-align: center;
  color: ${props => props.theme.palette.secondary.main};
  justify-content: center;

  &:hover {
    color: alpha(common.white, 0.8);
  }
`;

export const DialogWrapperStyled = styled(Box)`
  background: ${props => props.theme.palette.primary.main};
  height: 100vh;
`;

export const DialogHeaderStyled = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin: 23px 15px;
`
export const CloseButtonStyled = styled(IconButton)`
`;
