import React from 'react';
import { createTheme } from '@mui/material/styles';
import { common, red } from '@mui/material/colors';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    boldTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    boldTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    boldTitle: true;
  }
}


const LinkBehavior = React.forwardRef<HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>((props, ref) => {
  const { href, ...other } = props;
  if (href.toString().match(/^https?:\/\//)) {
    return <a ref={ref} href={href.toString()} {...other} >{props.children}</a>;
  }
  return <RouterLink ref={ref} to={href} {...other} />;
});

export const customColors = {
  lightBlue: '#bbd3e8',
  darkBlue: '#1f4658',
  darkerBlue: '#142A3D',
  darkGreen: '#004324'
};

const avenirBlackStyles = {
  fontFamily: 'Avenir Black',
  fontSize: '12px',
};

const signifierStyles = {
  fontFamily: 'Signifier Medium',
};

const theme = createTheme({
  palette: {
    primary: {
      main: customColors.darkBlue,
    },
    secondary: {
      main: customColors.lightBlue,
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: customColors.darkerBlue,
    },
  },

  typography: {
    boldTitle: avenirBlackStyles,
    h1: {
      ...signifierStyles,
      fontSize: '26px',
    },
    subtitle2: {
      ...signifierStyles,
      fontSize: '38px',
      lineHeight: 1.3,
    },
    subtitle1: {
      ...signifierStyles,
      fontSize: '21px',
      lineHeight: 1.4,
      marginBottom: 4,
    },
    fontFamily: [
      'Avenir Medium',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
        disableGutters: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          ...avenirBlackStyles,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: avenirBlackStyles,
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: customColors.darkBlue,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '25px 20px 15px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: customColors.lightBlue,
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        },
        content: {
          alignItems: 'center',
          margin: '9px 0',
          '&.Mui-expanded': {
            margin: '9px 0',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        fullWidth: avenirBlackStyles,
        root: {
          '&.Mui-disabled': {
            color: common.white,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
      },
    },
  },
});

export default theme;
