import { Dialog, List, Slide, Typography } from '@mui/material';

import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { CloseButtonStyled, DialogHeaderStyled, DialogWrapperStyled, MenuItemStyled } from './Header.styled';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  const { children, ...restProps } = props;
  return <Slide direction='up' ref={ref} {...restProps}>
    {props.children}
  </Slide>;
});

interface IProps {
  isOpen: boolean;

  onClose(): void;
}

const NavigationDialog = ({ isOpen, onClose }: IProps) => {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => () => {
    navigate(path);
    onClose();
  };

  return <Dialog
    fullScreen
    open={isOpen}
    onClose={onClose}
    TransitionComponent={Transition}
  >
    <DialogWrapperStyled>
      <DialogHeaderStyled>
        <CloseButtonStyled
          color='secondary'
          onClick={onClose}
          aria-label='close'
        >
          <CloseIcon sx={{ fontSize: 45 }} />
        </CloseButtonStyled>
      </DialogHeaderStyled>
      <List>
        <MenuItemStyled button onClick={handleNavigate('/update-weight')}>
          <Typography variant={'subtitle2'}>Update Weight</Typography>
        </MenuItemStyled>
        <MenuItemStyled button onClick={handleNavigate('/')}>
          <Typography variant={'subtitle2'}>Overview</Typography>
        </MenuItemStyled>
        <MenuItemStyled button onClick={handleNavigate('/insight')}>
          <Typography variant={'subtitle2'}>Insights</Typography>
        </MenuItemStyled>
        <MenuItemStyled button onClick={handleNavigate(`/feeding`)}>
          <Typography variant={'subtitle2'}>Feeding Instructions</Typography>
        </MenuItemStyled>
        <MenuItemStyled button onClick={handleNavigate(`/gallery`)}>
          <Typography variant={'subtitle2'}>Gallery</Typography>
        </MenuItemStyled>
        <MenuItemStyled button onClick={() => window.location.href = `https://breedscience.com/account`}>
          <Typography variant={'subtitle2'}>Account</Typography>
        </MenuItemStyled>
      </List>
    </DialogWrapperStyled>

  </Dialog>
    ;
};

export default NavigationDialog;
