import {Box, Typography} from "@mui/material";

export function ProgressBar({progress = 0}) {

  const color = progress > 75 ? '#142A3D' : '#fff';
  const position = progress > 75 ? 'calc(50% - 16px)' : 11;

  return (
    <Box height={32} bgcolor={'#142A3D'} borderRadius={15} width={'100%'} padding={'5px'} position={'relative'}>
      <Box height={22} bgcolor={'#fff'} borderRadius={11} minWidth={22} width={`${progress}%`}>
        &nbsp;
      </Box>
      <Box position={'absolute'} right={position} top={0}>
        <Typography color={color} lineHeight={2} fontSize={16}>{progress}%</Typography>
      </Box>
    </Box>
  )
}
