import React, {useEffect, useMemo, useState} from 'react';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import StaticCalendarLayout from '@/components/calendar/StaticCalendarLayout';
import { Box, Container, Stack } from '@mui/material';
import { UWButtonStyled } from '@/views/pages/update-weight/UpdateWeightPage.styled';
import { customColors } from '@/theme';
import { common } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { api } from "@/app/api";
import { useAppSelector } from "@/hooks/useRedux";

const WeightCalendarPage = () => {
  const navigate = useNavigate();
  const [submitNewWeight] = api.useUpdateWeightMutation();
  const weightLb = useAppSelector(state => state.common.updateWeightForm.weightLb);
  const patientId = useAppSelector( state => state.common.selectedDiet?.patient?.id);
  const startDay = useAppSelector( state => state.common.selectedDiet?.startOn);
  const weightRecords = useAppSelector( state => state.common.selectedDiet?.progress);
  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
  const markedDays = useMemo(() => {
    const days = new Set();
    weightRecords?.forEach((rec: any) => {
      days.add(rec.date as string)
    })
    return days;
  }, [weightRecords])

  const editableDays = dayjs(startDay).add(1, 'day');

  useEffect(() => {
    if (!weightLb) {
      navigate('/update-weight', { replace: true });
    }
  }, [ weightLb, navigate ])

  const renderDay = (props: any) => {
    const isStartDay = markedDays.has(dayjs(props.day).format('YYYY-MM-DD') );
    return <div className={isStartDay ? 'dayBadge' : ''}>
      {isStartDay && <span className={'dayBadgeIcon'}> </span>}
      <PickersDay {...props} />
    </div>;
  };

  const handleUpdate = async () => {
    await submitNewWeight({weight: weightLb, date: dayjs(date).format('YYYY-MM-DD'), id: patientId});
    navigate('/update-weight-complete');
  }

  return <Container disableGutters={true} maxWidth={false} sx={{ backgroundColor: customColors.darkBlue }}>
    <Stack
      sx={{ height: `calc(100vh - 100px)`, justifyContent: 'space-around' }}
      spacing={3}
    >
      <StaticDatePicker
        orientation={'portrait'}
        autoFocus={true}
        disableFuture={true}
        shouldDisableDate={ day => editableDays.isAfter(day) }
        reduceAnimations={true}
        slots={{
          layout: StaticCalendarLayout,
          day: renderDay,
        }}
        views={['day']}
        slotProps={{
          toolbar: { hidden: true },
          actionBar: { hidden: true },
        }}
        monthsPerRow={4}
        disableHighlightToday={false}
        value={date}
        onChange={(value) => setDate(value)}
      />
      <Box sx={{ paddingX: '16px' }}>
        <UWButtonStyled
          onClick={handleUpdate}
          variant={'outlined'}
          color={'info'}
          fullWidth
          disabled={weightLb <= 0.5}
          sx={{
            borderColor: common.white,
            color: common.white,
            '&:hover': {
              borderColor: common.white,
            },
          }}
        >
          Update Weight
        </UWButtonStyled>
      </Box>
    </Stack>
  </Container>;
};

export default WeightCalendarPage;
