import React, { FormEvent, useCallback, useRef } from 'react';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import { api } from '@/app/api';
import { useNavigate } from 'react-router-dom';

export function SessionExpiredPage() {
  const emailRef = useRef();
  const navigate = useNavigate();
  const [recoverAccess, { isLoading }] = api.useRecoverAccessMutation();

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const email = (event.target as any).elements.email.value;
      const result = await recoverAccess({ email });
      if ('data' in result) {
        navigate('/check-email');
      }
    },
    [navigate, recoverAccess]
  );

  return (
    <>
      <Typography
        variant={'h5'}
        fontWeight={'bold'}
        sx={{ m: 2 }}
        textAlign={'center'}
      >
        Oops! Your link has expired or cannot be found.
      </Typography>
      <Typography variant={'body1'} textAlign={'center'}>
        Please enter your email and we’ll send you a magic link to sign in:
      </Typography>

      <Stack
        paddingTop={1}
        gap={5}
        alignItems={'center'}
        component={'form'}
        onSubmit={onSubmit}
      >
        <FormControl variant="standard" fullWidth>
          <InputLabel>Email address</InputLabel>
          <Input
            inputProps={{ inputMode: 'email', style: {paddingLeft: 8 }}}
            name={'email'}
            ref={emailRef}
          />
        </FormControl>

        <Button
          type={'submit'}
          variant={'contained'}
          color={'primary'}
          sx={{ width: 200, height: 40 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color={'inherit'} sx={{ m: '4px' }} size={20} />
          ) : (
            'Continue'
          )}
        </Button>
      </Stack>
    </>
  );
}
