import * as React from 'react';
import { useState } from 'react';
import { Badge, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NavigationDialog from './NavigationDialog';
import logo from '@/assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useNotifications } from '@/app/hooks';

export default function Header() {
  const navigate = useNavigate();
  const { unreadNotifications } = useNotifications();
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box>
        <Toolbar>
          <Box>
            <IconButton
              size='large'
              aria-label='notifications'
              color='primary'
              onClick={() => navigate('/messages')}
            >
              <Badge variant={'dot'} color='error' overlap='circular' invisible={!unreadNotifications.length}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Link to={'/'}>
            <img src={logo} alt={'breed science'} width={74} />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size='large'
            edge='start'
            color='primary'
            aria-label='open drawer'
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Box>
      <NavigationDialog isOpen={open} onClose={() => setOpen(false)} />
    </Box>
  );
}
