import { Box, styled } from '@mui/material';

const RoundedIconStyled = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background: ${props => props.theme.palette.primary.main};
  padding: 5px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
`;

export default RoundedIconStyled;
