import {
  Area,
  ComposedChart,
  Dot,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import React, { useMemo } from 'react';
import { common } from '@mui/material/colors';
import Heart from '@/assets/icons/heart.svg';
import { Props } from 'recharts/types/component/Label';
import { useDietPlans } from '@/app/hooks';
import dayjs from 'dayjs';
import { Box, Stack, Typography } from '@mui/material';
import { customColors } from '@/theme';
import { round2 } from '@/helpers/weightConverter';

const transformToChart = (
  forecast: { date: string; weight: number }[],
  progress: {
    date: string;
    weight: number;
  }[]
) => {
  if (forecast.length === 0 || progress.length === 0) {
    return [];
  }
  const forecastData = forecast.reduce((acc, data) => {
    acc[data.date] = data.weight;
    return acc;
  }, {} as Record<string, number>);
  const progressData = progress.reduce((acc, data) => {
    acc[data.date] = data.weight;
    return acc;
  }, {} as Record<string, number>);

  const start = dayjs(
    forecast[0].date < progress[0].date ? forecast[0].date : progress[0].date,
    'YYYY-MM-DD'
  );

  const end = dayjs(
    forecast.at(-1)!.date > progress.at(-1)!.date
      ? forecast.at(-1)!.date
      : progress.at(-1)!.date,
    'YYYY-MM-DD'
  );

  let date = dayjs(start, 'YYYY-MM-DD');
  const chartData = [];

  while (!end.isBefore(date)) {
    const key = date.format('YYYY-MM-DD');
    chartData.push({
      dayNum: date.diff(start, 'day'),
      key,
      isStart: forecast[0].date === key,
      isEnd: forecast.at(-1)!.date === key,
      forecast: forecastData[key],
      progress: progressData[key],
    });
    date = date.add(1, 'day');
  }

  const startIndex = chartData.findIndex((rec) => rec.isStart);
  if (startIndex > 0) {
    const fill = chartData[startIndex].forecast;
    for (let i = 0; i < startIndex; i++) {
      chartData[0].forecast = fill;
    }
  }

  // add extra week from edges for better chart view
  for (let i = 1; i < 8; i++) {
    chartData.unshift({
      dayNum: chartData[0].dayNum - i,
      key: start.add(-i, 'day').format('YYYY-MM-DD'),
      isStart: false,
      isEnd: false,
      forecast: chartData[0].forecast,
      progress: undefined,
    });
    chartData.push({
      dayNum: chartData.at(-1)!.dayNum + i,
      key: end.add(i, 'day').format('YYYY-MM-DD'),
      isStart: false,
      isEnd: false,
      forecast: chartData.at(-1)!.forecast,
      progress: undefined,
    });
  }

  return chartData;
};

const DotElement = (props: any) => {
  const { cx, cy, chartData, index } = props;
  if (chartData[index].isStart || chartData[index].isEnd) {
    return <Dot r={4} fill="white" stroke={'#004324'} cx={cx} cy={cy} />;
  }
  return <> </>;
};

const LabelElement = (props: any) => {
  const { x, y, value, index, chartData } = props;
  const isFirstPoint = chartData[index].isStart;
  const isEndPoint = chartData[index].isEnd;
  if (isFirstPoint || isEndPoint) {
    return (
      <g fill={customColors.darkGreen} className="recharts-text recharts-label">
        <text
          x={x + 5}
          y={y}
          dy={isFirstPoint ? -15 : 20}
          dx={isFirstPoint ? 5 : -10}
          textAnchor={isFirstPoint ? 'start' : 'end'}
        >
          <tspan fontWeight={'bold'}>{round2(value)} lb</tspan>
        </text>
        <text
          x={x + 5}
          y={y}
          dy={isFirstPoint ? 0 : 35}
          dx={isFirstPoint ? 5 : -10}
          textAnchor={isFirstPoint ? 'start' : 'end'}
        >
          <tspan>{dayjs(chartData[index].key).format('MMM')}</tspan>
        </text>
      </g>
    );
  }
  return <></>;
};

const WeightLossTrendWidget = () => {
  const { selectedDiet } = useDietPlans();

  const chartData = useMemo(
    () =>
      transformToChart(
        selectedDiet?.forecast ?? [],
        selectedDiet?.progress ?? []
      ),
    [selectedDiet]
  );

  return (
    <Box sx={{ position: 'relative' }} width={'100%'} height={350}>
      <Stack
        sx={{
          position: 'absolute',
          width: '100%',
          alignItems: 'center',
          top: '20px',
          zIndex: '100',
        }}
      >
        <Typography
          fontWeight={'bold'}
          color={customColors.darkGreen}
          fontSize={22}
        >
          {round2(selectedDiet?.currentWeight?.weight)} lb
        </Typography>
      </Stack>
      <ResponsiveContainer>
        <ComposedChart
          data={chartData}
          margin={{ top: 50, bottom: 40 }}
          style={{ backgroundColor: '#DDEBE5' }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="30%" stopColor="#A0D5BB" stopOpacity={1} />
              <stop offset="70%" stopColor="#DDEBE5" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="forecast"
            stroke="#004324"
            fillOpacity={1}
            fill="url(#colorPv)"
            connectNulls={true}
            dot={(props) => <DotElement {...props} chartData={chartData} />}
            label={(props: Props) => (
              <LabelElement {...props} chartData={chartData} />
            )}
          />
          <Line
            type={'linear'}
            connectNulls={true}
            dot={{ fill: 'white' }}
            dataKey={'progress'}
            stroke={'#FF9C74'}
            strokeWidth={1.5}
          />
          <YAxis domain={['dataMin', 'dataMax']} hide={true} />
          <XAxis
            domain={['dataMin', 'dataMax']}
            dataKey={'dayNum'}
            hide={true}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default () => ({
  key: 'weight-loss-trend',
  label: 'Weight loss Trend',
  icon: Heart,
  outlinedIcon: true,
  sx: {
    '.MuiAccordionSummary-root': {
      backgroundColor: common.white,
    },
    '.MuiAccordionDetails-root': {
      backgroundColor: '#E0EDE7',
      padding: 0,
    },
  },
  isOpen: true,
  widget: <WeightLossTrendWidget />,
});
