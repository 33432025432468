import dayjs from 'dayjs';

const lastUpdateLabel = (data: any) => {
  let str = 'Last Update: ';
  let day = 'N/A';
  if (data) {
    const [lastDate] = data;
    if (lastDate) {
      day = dayjs(lastDate.date).isToday()
        ? 'Today'
        : dayjs(lastDate.date).isYesterday()
          ? 'Yesterday'
          : dayjs(lastDate.date).format('MMMM DD');
    }
  }
  return str + day;
}

export default { lastUpdateLabel };
