import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import Routes from './routes';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './styles.css';
import WeekDayAdapter from '@/components/calendar/WeekDayAdapter';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={WeekDayAdapter}>
        <CssBaseline />
        <Provider store={store}>
          <Routes />
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
