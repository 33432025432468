import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from './store';
import { DietPlan, PatientPhoto } from '@/types/common';

const baseQuery = fetchBaseQuery({
  baseUrl: '/api',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
  },
});

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'DietPlans',
    'Notifications',
    'WeightUpdate',
    'TrackerReport',
    'Gallery',
  ],
  endpoints: (builder) => ({
    login: builder.mutation<{ token: string }, string>({
      query: (otp) => ({
        method: 'POST',
        url: '/auth/login',
        body: { otp },
      }),
    }),
    recoverAccess: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: 'POST',
        url: '/auth/recover',
        body: { email },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: '/auth/logout',
      }),
    }),
    dietPlans: builder.query<DietPlan[], void>({
      query: () => '/wlp/patients',
      keepUnusedDataFor: 30,
      providesTags: ['DietPlans'],
    }),
    notifications: builder.query<any, number>({
      query: (skip: number) => `/notifications?skip=${skip}`,
      providesTags: ['Notifications'],
    }),
    readNotification: builder.mutation<any, string>({
      query: (id: string) => ({
        method: 'PATCH',
        url: `/notifications/${id}`,
        body: { read: true },
      }),
      invalidatesTags: ['Notifications'],
    }),
    updateWeight: builder.mutation<any, any>({
      invalidatesTags: () => [{ type: 'DietPlans' }],
      query: ({ id, weight, date }) => ({
        method: 'PATCH',
        url: `/wlp/patient/weight`,
        params: { id },
        body: {
          weight,
          date,
        },
      }),
    }),
    trackerReport: builder.query<any, string>({
      query: (dogName: string) => ({
        url: `/tracker`,
        params: { dogName },
      }),
      providesTags: ['TrackerReport'],
    }),
    patientPhotos: builder.query<PatientPhoto[], string>({
      query: (id) => ({
        url: `/gallery`,
        params: { patient: id },
      }),
      providesTags: () => ['Gallery'],
    }),
    deletePatientPhoto: builder.mutation<any[], string>({
      query: (id) => ({
        method: 'DELETE',
        url: `/gallery`,
        params: { id },
      }),
      invalidatesTags: ['Gallery'],
    }),
    uploadPhoto: builder.mutation<
      any,
      { mimeType: string; patientId: string; weight: number; date: string; content: File }
    >({
      async queryFn(
        { mimeType, weight, date, patientId, content },
        _queryApi,
        _extraOptions,
        fetchWithBaseQuery
      ) {
        const response = await fetchWithBaseQuery({
          url: '/gallery/upload/prepare',
          method: 'post',
          body: { mime: mimeType, patientId, weight, date },
        });
        if ('error' in response) {
          return { error: response.error as FetchBaseQueryError };
        }
        const { url: s3Url, id } = response.data as any;

        await fetch(s3Url, {
          method: 'PUT',
          body: content,
        });

        return fetchWithBaseQuery({
          url: '/gallery/upload/complete',
          method: 'post',
          params: { id },
        });
      },
      invalidatesTags: ['Gallery'],
    }),
  }),
});
