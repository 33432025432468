import { Button, ButtonGroup, Input, styled, Typography } from '@mui/material';
import { customColors } from '@/theme';

export const SwitcherWrapperStyled = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
  margin: 15px 0;

  Button:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  Button:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
`;

export const SwitcherBtnStyled = styled(Button)`
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  height: 50px;
`;

export const UWButtonStyled = styled(SwitcherBtnStyled)`
  &:not(.Mui-disabled):not(.MuiButton-outlined) {
    background-color: ${customColors.darkerBlue};
  }
`;

export const InputStyled = styled(Input)`
  font-size: 3rem;
  & input {
    text-align: center;
    //padding-right: 1rem;
  }
`;

export const InputLabelStyled = styled(Typography)`
  transform-origin: right top;
  right: 0;
  top: 0;
  position: absolute;
  transform: translate(0px, -15px) scale(1);
  color: gray;
  font-weight: bold;
`;
