import {useMemo} from 'react';
import {createBrowserRouter, Navigate, RouteObject, RouterProvider} from 'react-router-dom';
import { useInit } from './app/hooks';
import { MainLayout } from './views/layout/MainLayout';
import { OverviewPage } from './views/pages/overview/OverviewPage';
import MessagesPage from '@/views/pages/messages/MessagesPage';
import FeedingInstructionPage from '@/views/pages/feeding-instruction/FeedingInstructionPage';
import UpdateWeightPage from '@/views/pages/update-weight/UpdateWeightPage';
import WeightCalendarPage from '@/views/pages/update-weight/WeightCalendarPage';
import InsightPage from '@/views/pages/insight/InsightPage';
import { PageLoader } from "@/components/activity/page-loader";
import { UnAuthenticatedLayout } from "@/views/layout/UnAuthenticatedLayout";
import { SessionExpiredPage } from './views/pages/auth/SessionExpiredPage';
import { CheckEmailPage } from "@/views/pages/auth/CheckEmailPage";
import { GalleryPage } from "@/views/pages/gallery/GalleryPage";
import { ThankYouPage } from "@/views/pages/update-weight/ThankYouPage";

export default function Routes() {

  const { isLoading, loggedIn } = useInit();

  const routeConfig = useMemo((): RouteObject => {
    if (isLoading) {
      return {
        element: <PageLoader />,
        path: '*',
      };
    }

    if (!loggedIn) {
      return {
        element: <UnAuthenticatedLayout />,
        children: [
          { path: '/check-email', element: <CheckEmailPage />},
          { path: '*', element: <SessionExpiredPage />},
        ]
      };
    }

    return {
      element: <MainLayout />,
      children: [
        { path: '', element: <OverviewPage /> },
        { path: '/messages', element: <MessagesPage /> },
        { path: '/feeding', element: <FeedingInstructionPage /> },
        { path: '/insight', element: <InsightPage /> },
        { path: '/gallery', element: <GalleryPage /> },
        { path: '/update-weight', element: <UpdateWeightPage /> },
        { path: '/weight-calendar', element: <WeightCalendarPage /> },
        { path: '/update-weight-complete', element: <ThankYouPage /> },
        { path: '*', element: <Navigate to={'/'} /> }
      ],
    };
  }, [loggedIn, isLoading]);

  const router = createBrowserRouter([{
    children: [routeConfig],
  }]);

  return <RouterProvider router={router} />;
}
