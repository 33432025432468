import PageTitle from '@/components/page-title/PageTitle';
import { Box, Container, Stack } from '@mui/material';
import HintBox from '@/components/hint-box/HintBox';
import React, { useState } from 'react';
import {
  SwitcherBtnStyled,
  SwitcherWrapperStyled,
  UWButtonStyled,
} from '@/views/pages/update-weight/UpdateWeightPage.styled';
import { useNavigate } from 'react-router-dom';
import KgInput from '@/views/pages/update-weight/components/KgInput';
import LbInput from '@/views/pages/update-weight/components/LbInput';
import { Units } from '@/types/common';
import { useUpdateWeight } from '@/app/hooks';

const UpdateWeightPage = () => {
  const navigate = useNavigate();
  const { saveNewWeight, mappedWeight, updateWeightByUnit } = useUpdateWeight();

  const [selectedUnit, setSelectedUnit] = useState<Units>(Units.POUNDS);

  const buttonVariant = (unit: Units): 'contained' | 'outlined' => {
    return selectedUnit === unit ? 'contained' : 'outlined';
  };

  const handleNextPage = () => {
    saveNewWeight(mappedWeight.weight);
    navigate('/weight-calendar');
  };

  const isDisabled = !(mappedWeight.weight > 0.5);

  return <Container maxWidth={false}>
    <PageTitle title={'Update Weight'} />
    <Stack spacing={3} pb={3}>
      <HintBox titlePrefix={'⚖️'} storeKey={'update_weight'}>
        It’s very important to get on a scale and update the current weight so our systems can provide dynamic
        support.
      </HintBox>
      <Stack display={'flex'} flexDirection={'column'} sx={{ height: '350px' }}>
        <Box sx={{ padding: '0 38px', marginTop: 4 }}>
          {
            selectedUnit === Units.KG
              ? <KgInput
                  onChange={updateWeightByUnit}
                  kgsValue={mappedWeight.kilograms}
              />
              : <LbInput
                  onChange={updateWeightByUnit}
                  poundValue={mappedWeight.pounds}
                  ounceValue={mappedWeight.ounces}
              />
          }
          <SwitcherWrapperStyled>
            <SwitcherBtnStyled
              onClick={() => setSelectedUnit(Units.POUNDS)}
              variant={buttonVariant(Units.POUNDS)}
            >
              LB
            </SwitcherBtnStyled>
            <SwitcherBtnStyled
              onClick={() => setSelectedUnit(Units.KG)}
              variant={buttonVariant(Units.KG)}>
              KG
            </SwitcherBtnStyled>
          </SwitcherWrapperStyled>
        </Box>
      </Stack>
      <UWButtonStyled
        onClick={handleNextPage}
        variant={'contained'}
        fullWidth
        disabled={isDisabled}
      >
        Update Weight
      </UWButtonStyled>
    </Stack>
  </Container>;
};

export default UpdateWeightPage;
