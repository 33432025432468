import {
  Typography,
} from '@mui/material';
export function CheckEmailPage() {
  return (
    <>
      <Typography
        variant={'h5'}
        fontWeight={'bold'}
        sx={{ m: 2 }}
        textAlign={'center'}
      >
        Your magic link is on its way!
      </Typography>
      <Typography variant={'body1'} textAlign={'center'}>
        Your magic link is on its way, if your email address is associated with an active account, we will send you a login.
      </Typography>
    </>
  );
}
