import React, { useState } from 'react';
import { Box, ButtonBase } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

import { SliderImageStyled } from './SliderImageStyled';
import { PhotoSliderProps } from './types';
import { fireGTMEvent } from '@/helpers/gtag';

export function NewImageUploadSlide({
  uploadPhoto,
  currentWeight,
  patientId,
}: Pick<PhotoSliderProps, 'uploadPhoto' | 'currentWeight' | 'patientId'>) {
  const [file, setFile] = useState<any>(null);

  function handleChange(e: any) {
    const file: File = e.target.files[0];
    setFile(URL.createObjectURL(file));
    uploadPhoto({
      ...currentWeight,
      patientId,
      content: file,
      mimeType: file.type,
    });
    fireGTMEvent('photo_uploaded', {
      id: file.name,
      type: file.type,
      size: file.size,
    });
  }

  if (!file) {
    return (
      <Box
        sx={{
          width: '100%',
          aspectRatio: '4/5',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          bgcolor: '#f8f8f8',
        }}
      >
        <ButtonBase
          data-action={'photo_gallery_open'}
          sx={{
            width: 200,
            height: 200,
            borderRadius: 100,
            position: 'relative',
            fontSize: 72,
            lineHeight: 1,
          }}
        >
          <PhotoCamera
            fontSize={'inherit'}
            data-action={'photo_gallery_open'}
          />
          <input
            data-action={'photo_gallery_open'}
            type={'file'}
            onChange={handleChange}
            accept="image/*"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              borderRadius: 100,
              opacity: 0,
            }}
          />
        </ButtonBase>
      </Box>
    );
  }
  return (
    <Box position={'relative'}>
      <SliderImageStyled className={'photo'} src={file} alt={'new photo'} />
    </Box>
  );
}
