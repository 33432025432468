import { api } from './api';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { commonSlice } from '@/app/commonSlice';
import { Units } from '@/types/common';
import { useCallback, useEffect, useState } from 'react';
import { MappedWeight } from '@/helpers/MappedWeight';

export function useInit() {
  const wasLoggedIn = useAppSelector((state) => Boolean(state.auth.token));
  const [login] = api.useLoginMutation();
  const [isLoading, setIsLoading] = useState(true);
  // console.log({loggedIn: wasLoggedIn, isLoading, href: window.location.href})
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isLoading) {
      return;
    }
    (async () => {
      const url = new URL(window.location.href);
      let isLoggedIn = wasLoggedIn;
      if (url.searchParams.has('otp')) {
        isLoggedIn = await login(url.searchParams.get('otp')!)
          .unwrap()
          .then(() => true)
          .catch(() => false);
        url.searchParams.delete('otp');
      }

      if (isLoggedIn || wasLoggedIn) {
        const diets = await dispatch(api.endpoints.dietPlans.initiate())
          .unwrap()
          .catch(() => null);
        if (!diets || diets.length === 0) {
          window.location.assign('https://breedscience.com/account');
          return;
        }

        const switchToDog = url.searchParams.get('dogId');
        if (switchToDog) {
          url.searchParams.delete('dogId');
          const diet = diets.find((d) => d.patient.id === switchToDog);
          dispatch(commonSlice.actions.selectDiet(diet ?? diets[0]));
        }
        window.history.replaceState(null, document.title, url);
      } else {
        if (url.searchParams.has('dogId')) {
          url.searchParams.delete('dogId');
          window.history.replaceState(null, document.title, url);
        }
      }
      setIsLoading(false);
    })();
  }, [dispatch, login, wasLoggedIn, isLoading]);

  return {
    loggedIn: wasLoggedIn,
    isLoading,
  };
}

export function useDietPlans() {
  const { data: diets, ...rest } = api.useDietPlansQuery();
  const dispatch = useAppDispatch();
  const selectedDiet = useAppSelector((state) => state.common.selectedDiet);

  const chooseDiet = (diet: any) => {
    dispatch(commonSlice.actions.selectDiet(diet));
  };

  return {
    diets: diets!,
    selectedDiet,
    chooseDiet,
    ...rest,
  };
}

export function useNotifications() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.common.notifications);
  const offset = useAppSelector((state) => state.common.notificationOffset);

  const { data, ...rest } = api.useNotificationsQuery(offset);

  const unreadNotifications = notifications.filter((note: any) => !note.read);

  const readAllNotifications = () => {
    unreadNotifications.forEach((note: any) => {
      dispatch(api.endpoints.readNotification.initiate(note.id));
    });
  };

  const nextNotifications = () => {
    dispatch(commonSlice.actions.setNotificationOffset(offset + 20));
  };

  return {
    notifications,
    unreadNotifications,
    paging: data?.paging,
    readAllNotifications,
    nextNotifications,
    ...rest,
  };
}

export function useUpdateWeight() {
  const dispatch = useAppDispatch();

  const currentDiet = useAppSelector((state) => state.common.selectedDiet);
  const currentWeight = currentDiet?.currentWeight?.weight ?? 0;
  const [mappedWeight, setMappedWeight] = useState<MappedWeight>(
    new MappedWeight(currentWeight)
  );
  const updateWeightByUnit = useCallback(
    (value: string, unit: Units) => {
      setMappedWeight(mappedWeight.update(value, unit));
    },
    [setMappedWeight, mappedWeight]
  );

  const saveNewWeight = (weight: number) => {
    dispatch(commonSlice.actions.setWeight(weight));
  };

  useEffect(() => {
    if (currentDiet?.currentWeight?.weight) {
      setMappedWeight(
        new MappedWeight(currentDiet?.currentWeight?.weight ?? 0)
      );
    }
  }, [currentDiet]);

  return {
    mappedWeight,
    updateWeightByUnit,
    saveNewWeight,
  };
}

export function useDogName() {
  const selectedDiet = useAppSelector((state) => state.common.selectedDiet);
  return selectedDiet?.patient?.dogName || '';
}

export function useWeightLossProgress() {
  const selectedDiet = useAppSelector((state) => state.common.selectedDiet);
  if (!selectedDiet) {
    return 0;
  }
  try {
    const {
      idealWeight,
      initialWeight,
      currentWeight: { weight: currentWeight },
    } = selectedDiet;
    const progress = Math.round(
      (100 * (initialWeight - currentWeight)) / (initialWeight - idealWeight)
    );
    return Math.max(Math.min(progress, 100), 0);
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export function useTrackerReport() {
  const dogName = useDogName();
  const { data, ...rest } = api.useTrackerReportQuery(dogName, {
    skip: !dogName,
  });
  return {
    data,
    ...rest,
  };
}
