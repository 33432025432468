import { FormControl, Stack } from '@mui/material';
import React from 'react';
import { InputLabelStyled, InputStyled } from '@/views/pages/update-weight/UpdateWeightPage.styled';
import { Units } from '@/types/common';

const LbInput = ({ poundValue, ounceValue, onChange }: { poundValue?: number, ounceValue?: number, onChange(val: string, unit: string): void; }) => {
  return <Stack spacing={2} direction={'row'}>
    <FormControl variant='outlined'>
      <InputLabelStyled htmlFor='pounds'>Pounds</InputLabelStyled>
      <InputStyled
        id={'pounds'}
        aria-describedby='outlined-weight-pounds'
        onChange={({ target }: any) => onChange(target?.value, Units.POUNDS)}
        type={'number'}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
        placeholder={`0`}
        value={poundValue}
      />
    </FormControl>
    <FormControl variant='outlined'>
      <InputLabelStyled htmlFor='ounces'>Ounces</InputLabelStyled>
      <InputStyled
        id={'ounces'}
        aria-describedby='outlined-weight-ounces'
        onChange={({ target }: any) => onChange(target?.value, Units.OUNCES)}
        type={'number'}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 2 }}
        placeholder={`0`}
        value={ounceValue}
      />
    </FormControl>
  </Stack>;

};

export default LbInput;
